import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

import { RoleType } from 'src/app/shared/constants/role-type.constant';
import { User } from 'src/app/shared/models';
import { AuthService } from '../auth/services';
import { AppStateService } from '../auth/services/app-state.service';

@Injectable({
	providedIn: 'root',
})
export class AuthResolver implements Resolve<boolean> {
	constructor(
		private authService: AuthService,
		private appStateService: AppStateService,
		private router: Router,
	) {}

	async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		try {
			const res = await this.authService.getAuthFields();
			if (res.accessToken && res.refreshToken && res.user) {
				this.authService.setAuthFields(res.accessToken, res.refreshToken, res.user);
				const user = new User(res.user);
				this.appStateService.setReady({ app: true });
				const isImpersonating = this.authService.isImpersonating;

				if (await this.checkShouldRedirectPasswordExpired(user, state, isImpersonating)) {
					return false;
				}

				if (await this.checkShouldRedirectUsers(route, user.roleId)) {
					return false;
				}

				return true;
			}
		} catch (e) {
			// nothing
		}

		await this.returnToLogin(state);
		return false;
	}

	private async returnToLogin(state: RouterStateSnapshot): Promise<void> {
		this.appStateService.setReady({ app: false });
		await this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
	}

	private async checkShouldRedirectPasswordExpired(user: User, state: RouterStateSnapshot, isImpersonating: boolean): Promise<boolean> {
		if (!isImpersonating && user.isPasswordExpired && !state.url.includes('user/password-reset')) {
			await this.router.navigate(['/user/password-reset']);
			return true;
		}
		return false;
	}

	private async checkShouldRedirectUsers(route: ActivatedRouteSnapshot, roleId: number): Promise<boolean> {
		// only system or admin allowed here
		if ((route.data.isSystem && roleId !== RoleType.system) || (route.data.isAdmin && ![RoleType.system, RoleType.admin].includes(roleId))) {
			// only but an admin should be redirected to their default dashboard
			if (route.data.isSystem && !route.data.isAdmin && roleId === RoleType.admin) {
				await this.router.navigate(['/admin']);
			}

			if (route.data.isAdmin && ![RoleType.system, RoleType.admin].includes(roleId)) {
				// all others should redirect to their default dashboard
				await this.router.navigate(['/engagements']);
				return true;
			}
		}

		return false;
	}
}
